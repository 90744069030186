import * as React from "react"
import { Link, graphql } from "gatsby"
import PostLink from "../components/post-link"

// styles
const pageStyles = {
  color: "#232129",
  padding: "25px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const Blog = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <main style={pageStyles}>
      <title>Blog</title>
      <h1 style={headingStyles}>Ideas, Anecdotes, and Learnings.</h1>
      Posts:
      <br />
      <br />
      <div style={paragraphStyles}>
       {Posts}
      </div>
      <br />
      <br />
      <Link to="/">Go home</Link>

    </main>
  )
}
// , filter: {frontmatter: {slug: {regex: "/afaw/"}}};

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
  `

////




//    allMarkdownRemark(sort: { frontmatter: { date: DESC }}) {

